import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"testimonials"} />
		<Helmet>
			<title>
				Lagoon Linger: Dive into Delights
			</title>
			<meta name={"description"} content={"Dive into tales and tipples at Lagoon Linger."} />
			<meta property={"og:title"} content={"Lagoon Linger: Dive into Delights"} />
			<meta property={"og:description"} content={"Dive into tales and tipples at Lagoon Linger."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_4.jpg?v=2023-10-23T14:23:43.447Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/4474385.png?v=2023-10-23T14:46:12.244Z"} />
		</Helmet>
		<Components.ToluqyeHeader />
		<Section padding="80px 0 80px 0" background="--color-light">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 56px 0px"
				width="100%"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--darkL2"
					font="--headline1"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Testimonials:{" "}
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="--lead">
					Discover the enchanting experiences of our patrons at Lagoon Linger. Dive into their tales and see what magic awaits you on your next visit.
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				align-items="stretch"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text margin="0px 0px 20px 0px" color="--darkL2" font="--lead">
						"Never have I ever felt so entranced by a bar's atmosphere. Lagoon Linger doesn't just serve drinks – it serves moments. Ordering through a 'Message in a Bottle' was an experience I'll cherish!"
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--darkL2" font="normal 700 20px/1.5 --fontFamily-sans" margin="0px 0px 2px 0px">
								Emma
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text margin="0px 0px 20px 0px" color="--darkL2" font="--lead">
						"From the moment I stepped in, I felt like I was part of a story. The cocktails, the ambiance, the community - it's a unique blend that makes Lagoon Linger stand out. Can't wait for my next visit!"
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--darkL2" font="normal 700 20px/1.5 --fontFamily-sans" margin="0px 0px 2px 0px">
								Lucas
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text margin="0px 0px 20px 0px" color="--darkL2" font="--lead">
						"The Deep Dive cocktail rising from the bar's depths? Pure magic! Every detail in Lagoon Linger seems carefully thought out, making it an absolute delight to spend an evening here."
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--darkL2" font="normal 700 20px/1.5 --fontFamily-sans" margin="0px 0px 2px 0px">
								Charlotte
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text margin="0px 0px 20px 0px" color="--darkL2" font="--lead">
						"I came for a drink and left with memories. The storytelling corner, the moonlit ambiance, and the exquisite cocktails - everything felt like a gentle embrace. Lagoon Linger, you've won my heart!"
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--darkL2" font="normal 700 20px/1.5 --fontFamily-sans" margin="0px 0px 2px 0px">
								Michael
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_4.jpg?v=2023-10-23T14:23:43.447Z"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_4.jpg?v=2023-10-23T14%3A23%3A43.447Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_4.jpg?v=2023-10-23T14%3A23%3A43.447Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_4.jpg?v=2023-10-23T14%3A23%3A43.447Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_4.jpg?v=2023-10-23T14%3A23%3A43.447Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_4.jpg?v=2023-10-23T14%3A23%3A43.447Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_4.jpg?v=2023-10-23T14%3A23%3A43.447Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/friends_4.jpg?v=2023-10-23T14%3A23%3A43.447Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_7.jpg?v=2023-10-23T14:23:43.488Z"
							srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_7.jpg?v=2023-10-23T14%3A23%3A43.488Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_7.jpg?v=2023-10-23T14%3A23%3A43.488Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_7.jpg?v=2023-10-23T14%3A23%3A43.488Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_7.jpg?v=2023-10-23T14%3A23%3A43.488Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_7.jpg?v=2023-10-23T14%3A23%3A43.488Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_7.jpg?v=2023-10-23T14%3A23%3A43.488Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_7.jpg?v=2023-10-23T14%3A23%3A43.488Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.1.jpg?v=2023-10-23T14:23:43.421Z"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
							srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.1.jpg?v=2023-10-23T14%3A23%3A43.421Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.1.jpg?v=2023-10-23T14%3A23%3A43.421Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.1.jpg?v=2023-10-23T14%3A23%3A43.421Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.1.jpg?v=2023-10-23T14%3A23%3A43.421Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.1.jpg?v=2023-10-23T14%3A23%3A43.421Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.1.jpg?v=2023-10-23T14%3A23%3A43.421Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.1.jpg?v=2023-10-23T14%3A23%3A43.421Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.2.jpg?v=2023-10-23T14:23:43.437Z"
							position="absolute"
							width="100%"
							left={0}
							srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.2.jpg?v=2023-10-23T14%3A23%3A43.437Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.2.jpg?v=2023-10-23T14%3A23%3A43.437Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.2.jpg?v=2023-10-23T14%3A23%3A43.437Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.2.jpg?v=2023-10-23T14%3A23%3A43.437Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.2.jpg?v=2023-10-23T14%3A23%3A43.437Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.2.jpg?v=2023-10-23T14%3A23%3A43.437Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_5.2.jpg?v=2023-10-23T14%3A23%3A43.437Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://uploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14:23:43.465Z"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
							srcSet="https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65367922cd81ee001f7e1fcb/images/people_9.jpg?v=2023-10-23T14%3A23%3A43.465Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					See you by the Lagoon!
				</Text>
			</Box>
		</Section>
		<Components.ToluqyeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});